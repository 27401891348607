<template>
    <section id="page-title">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h1>Planning</h1>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>